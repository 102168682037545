<template>
  <base-section
    id="better-athletes"
    space="64"
  >
    <!--    <v-container>-->
    <!--      <v-row>-->
    <!--        <v-col-->
    <!--          cols="4"-->
    <!--          offset="4"-->
    <!--          offset-sm="0"-->
    <!--          sm="12"-->
    <!--        >-->
    <base-section-heading
      icon="mdi-dumbbell"
      space="0"
      title="Better Athletes. By Design."
    >
      Your Olympic Weightlifting, strength training and performance expert.
      <br>
      Providing the knowledge and the tools you need to get stronger, faster, and build muscle!
      <br>
      Creating a better athlete with personalized programming and a culture to help you become your best self.
      <br>
      Giving you a positive experience is our passion!
    </base-section-heading>

    <!--            <base-body-->
    <!--              class="mb-6 d-block"-->
    <!--            >-->
    <!--            </base-body>-->

    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-container>-->
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCustomerReviews',

    provide: {
      heading: { align: 'left' },
    },

    data: () => ({
      customers: [
        {
          review: '"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam aspernatur, sint recusandae cum temporibus quos saepe repellendus consectetur et reprehenderit. Ratione ipsam consequuntur quo eligendi mollitia veniam facere possimus inventore."',
          name: 'HENRY LUSIO',
          role: 'DIRECTOR OF EXAMPLE LTD',
        },
        {
          review: '"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam aspernatur, sint recusandae cum temporibus quos saepe repellendus consectetur et reprehenderit. Ratione ipsam consequuntur quo eligendi mollitia veniam facere possimus inventore."',
          name: 'HENRY LUSIO',
          role: 'DIRECTOR OF EXAMPLE LTD',
        },
        {
          review: '"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam aspernatur, sint recusandae cum temporibus quos saepe repellendus consectetur et reprehenderit. Ratione ipsam consequuntur quo eligendi mollitia veniam facere possimus inventore."',
          name: 'HENRY LUSIO',
          role: 'DIRECTOR OF EXAMPLE LTD',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #customer-reviews
    .v-carousel__controls
      background: transparent

    .base-body
      line-height: 2
</style>
